import {RouteMap} from '../types/routes';

export const routes: RouteMap = {
  all: {
    paths: ['*'],
    components: {
      interface: () =>
        import(
          /* webpackChunkName: "interface" */
          './interface'
        ),
      global: () =>
        import(
          /* webpackChunkName: "global" */
          './global'
        ),
    },
  },
  audiences: {
    paths: ['/audiences'],
    components: {
      audiencesComponents: () =>
        import(
          /* webpackChunkName: "audiences" */
          './audiences'
        ),
    },
  },
  audienceBuilder: {
    paths: [
      '/v2/dataset_groups/:dataset_group_id/audiences/new',
      '/dataset_groups/:dataset_group_id/audiences/:id',
      '/v2/dataset_groups/:dataset_group_id/audiences/:id',
      '/v2/dataset_groups/:dataset_group_id/audiences/:id/edit',
    ],
    components: {
      audiencesComponents: () =>
        import(
          /* webpackChunkName: "audience_builder_v2" */
          './audience_builder'
        ),
    },
  },
  audiencesV1: {
    paths: [
      '/dataset_groups/:dataset_group_id/audiences/:id',
      '/dataset_groups/:dataset_group_id/audiences/:id/edit',
    ],
    components: {
      audiencesComponents: () =>
        import(
          /* webpackChunkName: "audiences_v1" */
          './audiences_v1'
        ),
    },
  },
  datasetGroups: {
    paths: [
      '/dataset_groups/:dataset_group_id/:id/edit',
      '/dataset_groups/:dataset_group_id/edit',
      '/dataset_groups/new',
    ],
    components: {
      audiencesComponents: () =>
        import(
          /* webpackChunkName: "audiences_v1" */
          './dataset_groups'
        ),
    },
  },
  journeyBuilder: {
    paths: [
      '/v2/journeys/new',
      '/v2/journeys/:id',
      '/v2/journeys/:id/edit',
      '/v2/dataset_groups/:dataset_group_id/journeys/:id',
      '/v2/dataset_groups/:dataset_group_id/journeys/:id/edit',
    ],
    components: {
      audiencesComponents: () =>
        import(
          /* webpackChunkName: "audience_builder_v2" */
          './journey_builder'
        ),
    },
  },
  journeys: {
    paths: ['/v2/journeys'],
    components: {
      journeysComponents: () =>
        import(
          /* webpackChunkName: "journeys" */
          './journeys'
        ),
    },
  },
  audienceStudio: {
    paths: ['/v2/dataset_groups/:dataset_group_id/audience_studio'],
    components: {
      audiencesComponents: () =>
        import(
          /* webpackChunkName: "audience_builder_v2" */
          './audience_studio'
        ),
    },
  },
  onboarding: {
    paths: ['/onboarding'],
    components: {
      onboardingComponents: () =>
        import(
          /* webpackChunkName: "onboarding" */
          './onboarding'
        ),
    },
  },
  destinations: {
    paths: ['/destination_platforms'],
    components: {
      destinationComponents: () =>
        import(
          /* webpackChunkName: "destinations" */
          './destinations'
        ),
    },
  },
  sources: {
    paths: ['/import_sources'],
    components: {
      sourceComponents: () =>
        import(
          /* webpackChunkName: "sources" */
          './sources'
        ),
    },
  },
  accessGrants: {
    paths: ['/v2/access_grants'],
    components: {
      accessGrantsComponents: () =>
        import(
          /* webpackChunkName: "access_grants" */
          './access_grants'
        ),
    },
  },
  adminOrganizations: {
    paths: ['/admin/organizations', '/admin/organizations/:id/edit'],
    components: {
      adminOrganizationsComponents: () =>
        import(
          /* webpackChunkName: "admin_organizations" */
          './admin_organizations'
        ),
    },
  },
  teams: {
    paths: ['/teams/:id/edit'],
    components: {
      teamsComponents: () =>
        import(
          /* webpackChunkName: "admin_teams" */
          './teams'
        ),
    },
  },
  marve: {
    paths: ['/v2/create'],
    components: {
      marve: () =>
        import(
          /* webpackChunkName: "marve" */
          './marve'
        ),
    },
  },
  home: {
    paths: ['/home'],
    components: {
      home: () =>
        import(
          /* webpackChunkName: "home" */
          './home'
        ),
    },
  },
  insights: {
    paths: ['/insights', '/insights/:id'],
    components: {
      insights: () =>
        import(
          /* webpackChunkName: "insights" */
          './insights'
        ),
    },
  },
  adminDebug: {
    paths: ['/admin/debug'],
    components: {
      adminDebugComponents: () =>
        import(
          /* webpackChunkName: "admin_debug" */
          './admin_debug'
        ),
    },
  },
  organization: {
    paths: ['/organization'],
    components: {
      organizationComponents: () =>
        import(
          /* webpackChunkName: "organization" */
          './organization'
        ),
    },
  },
  reports: {
    paths: [
      '/datasets/:dataset_id/audiences/new',
      '/dataset_groups/:dataset_group_id/audiences/new',
      '/dataset_groups/:dataset_group_id/audiences/:id/edit',
    ],
    components: {
      reports: () =>
        import(
          /* webpackChunkName: "reports" */
          './reports'
        ),
    },
  },
  embed: {
    paths: [
      '/embed/audiences/new',
      '/embed/audiences/:audience_id',
      '/embed/audiences/:audience_id/edit',
    ],
    components: {
      embedComponents: () => import('./embed'),
    },
  },
  reviewAudiences: {
    paths: ['/v2/review_audiences'],
    components: {
      reviewAudiencesComponents: () => import('./review_audiences'),
    },
  },
  audience: {
    paths: ['/dataset_groups/:dataset_group_id/audiences/:id'],
    components: {
      reports: () =>
        import(
          /* webpackChunkName: "audience" */
          './audience'
        ),
    },
  },
  sourceConnections: {
    paths: ['/datasets/new', '/datasets/:id/edit', '/source_connections'],
    components: {
      datasetsComponents: () =>
        import(
          /* webpackChunkName: "datasets" */
          './datasets'
        ),
    },
  },
  signalRoutes: {
    paths: ['/signal_routes'],
    components: {
      signalRoutesComponents: () =>
        import(
          /* webpackChunkName: "signal_routes" */
          './signal_routes'
        ),
    },
  },
  models: {
    paths: ['/models', '/models/new', '/models/:id/edit'],
    components: {
      modelsComponents: () =>
        import(
          /* webpackChunkName: "models" */
          './models'
        ),
    },
  },
  naturalLanguage: {
    paths: ['/v2/dataset_groups/:dataset_group_id/natural_language/new'],
    components: {
      naturalLanguageComponents: () =>
        import(
          /* webpackChunkName: "natural_language" */
          './natural_language'
        ),
    },
  },
};
